@charset "UTF-8";

//
// カラム
//

.columns {
  &:not(:first-child) {
    margin-top: 30px;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  @include is-laptop-wider {
    display: flex;
  }
  &__column {
    @include is-laptop-wider {
      flex: 1;
    }
    &:not(:first-child){
      margin-top: 30px;
      @include is-laptop-wider {
        margin-top: 0;
        margin-left: 15px;
      }
    }
    &:not(:last-child){
      margin-bottom: 30px;
      @include is-laptop-wider {
        margin-bottom: 0;
        margin-right: 15px;
      }
    }
  }
}
