@charset "UTF-8";

//
// メインビジュアル
//

.hero {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url('../../assets/images/privacy/bg_hero.png');
  @include is-laptop-wider {
    height: 300px;
  }
  &__heading {
    font-size: 2.5rem;
    letter-spacing: .3em;
    line-height: 1.2em;
    font-weight: 500;
    text-align: center;
    color: $color-key;
    @include is-laptop-wider {
      font-size: 3.7rem;
    }
    &::after {
      content: attr(data-en-text);
      display: block;
      margin-top: 8px;
      font-size: 1.5rem;
      letter-spacing: .06em;
      font-family: $font-english;
      @include is-laptop-wider {
        font-size: 2.5rem;
        margin-top: 13px;
      }
    }
  }
}
