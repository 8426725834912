@charset "UTF-8";

//
// ディスプレイ属性を強制的に変更
//

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

//
// 長い単語の途中でも強制的に改行
//

.break {
  word-wrap: break-word !important;
}

//
// 親要素の幅いっぱいに広げる
//

.full {
  box-sizing: border-box !important;
  width: 100% !important;
}

//
// 各方向のマージンをゼロに
//

.stick {
  margin: 0 !important;
}

.stick-bottom {
  margin-bottom: 0 !important;
}

.stick-left {
  margin-left: 0 !important;
}

.stick-right {
  margin-right: 0 !important;
}

.stick-top {
  margin-top: 0 !important;
}

//
// インラインコンテンツを寄せる
//

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

//
// 大文字・小文字の表示を変換
//

.text-lower {
  text-transform: lowercase;
}

.text-upper {
  text-transform: uppercase;
}


//
// 改行位置（単位）を指定
//

.word {
  display: inline-block;
}


//
// clearfix
//

%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}


//
// PCのみ表示
//

.pc-only {
  display: block;
}

@media print, screen and (max-width: 760px) {
  .pc-only {
    display: none;
  }
}


//
// SPのみ表示
//

.sp-only {
  display: none;
}

@media print, screen and (max-width: 760px) {
  .sp-only {
    display: block;
  }
}
