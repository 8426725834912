@charset "UTF-8";

//
// 画像
//

.figure {
  img {
    width: 100%;
    vertical-align: middle;
  }
}
