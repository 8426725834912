@charset "UTF-8";

//
// サイトヘッダ
//

.header {
  height: 60px;
  display: flex;
  align-items: center;
  @include is-desktop-narrower {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
  }
  @include is-laptop-wider{
    height: auto;
    display: block;
  }
  &__above {
    display: none;
    @include is-laptop-wider{
      display: block;
      background-color: #f5f5f5;
    }
    &__extra-nav {
      display: flex;
      justify-content: flex-end;
      @include is-desktop-narrower {
      }
      &__list {
        display: flex;
        @include is-desktop-narrower {
          flex-wrap: wrap;
          padding-left: 20px;
          padding-right: 20px;
          margin-left: -8px;
          margin-right: -8px;
        }
        @include is-laptop-wider{
          border-right: 1px solid #dfdfdf;
        }
        &__item {
          @include is-desktop-narrower {
            width: 50%;
            padding-left: 8px;
            padding-right: 8px;
            box-sizing: border-box;
            &:nth-child(n+3){
              margin-top: 15px;
            }
          }
          @include is-laptop-wider{
            border-left: 1px solid #dfdfdf;
          }
          &__anchor {
            display: block;
            font-size: 1.1rem;
            transition: $animation;
            padding: 15px 11px;
            @include is-desktop-narrower {
              background-color: #fff;
            }
            @include is-laptop-wider{
              padding: 20px 11px;
            }
            .is-current & {
              color: $color-key;
              @include is-laptop-wider{
              }
            }
            &:hover {
              @include is-laptop-wider{
                background-color: #dfdfdf;
              }
            }
            &__icon {
              display: block;
              position: relative;
              padding-right: 15px;
              @include is-laptop-wider{
              }
              &::after {
                position: absolute;
                top: 50%;
                right: 0;
                display: block;
                content: '';
                background-image: url('../../assets/images/common/ico_window.svg');
                background-size: 10px 8px;
                background-repeat: no-repeat;
                width: 10px;
                height: 8px;
                transform: translateY(-50%);
                .is-current & {
                  background-image: url('../../assets/images/common/ico_arrow_down_key.svg');
                  @include is-laptop-wider{
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__below {
    @include is-laptop-wider{
    }
    &__logo {
      width: 214px;
      @include is-desktop-narrower {
        position: fixed;
        top: 10px;
        left: 15px;
      }
      &__anchor {
        img {
          max-width: 100%;
          @include is-laptop-wider{
          }
        }
      }
    }
    &__columns {
      @include is-laptop-wider{
        display: flex;
        justify-content: space-between;
      }
      &__column {
        @include is-laptop-wider{
          display: flex;
          align-items: center;
        }
      }
    }
    &__nav {
      .is-palmtop-nav-open & {
        transform: translateX(0);
      }
      @include is-desktop-narrower {
        transform: translateX(100%);
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        height: calc(100vh - 60px);
        background-color: #F5F5F5;
        box-sizing: border-box;
        padding: 20px 0;
        transition: $animation;
      }
      @include is-laptop-wider{
        display: flex;
      }
      &__list {
        @include is-desktop-narrower {
          margin-top: 40px;
        }
        @include is-laptop-wider{
          display: flex;
        }
        &__item {
          $this: &;
          @include is-desktop-narrower {
            text-align: center;
          }
          @include is-laptop-wider{
            display: flex;
            align-items: flex-end;
            margin: 0 30px 0 27px;
            position: relative;
          }
          &:not(:first-child) {
            &:not(:last-child) {
              &:before {
                @include is-laptop-wider{
                  position: absolute;
                  bottom: 10px;
                  left: -30px;
                  content: '/';
                  display: block;
                  font-size: 1.3rem;
                  color: #737373;
                }
              }
            }
          }
          &__anchor {
            display: block;
            position: relative;
            @include is-desktop-narrower {
              font-size: 1.5rem;
              padding: 20px 0;
            }
            @include is-laptop-wider{
              display: block;
              font-size: 1.3rem;
              padding-bottom: 10px;
              transition: $animation;
            }
            &:hover {
              @include is-laptop-wider{
                color: $color-key;
              }
            }
            &__inner {
              position: relative;
              display: inline-block;
              @include is-desktop-narrower {
                padding-bottom: 10px;
              }
              &::after {
                .is-current & {
                  content: '';
                  width: 100%;
                  height: 2px;
                  background-color: $color-key;
                  position: absolute;
                  bottom: -1px;
                  left: 0;
                  z-index: 1;
                  @include is-desktop-wider {
                    bottom: -10px;
                  }
                }
              }
            }
          }
          &[data-category="contact"]{
            @include is-desktop-narrower {
              padding: 0 20px;
              margin-top: 20px;
            }
            @include is-laptop-wider{
              color: $color-base;
              background-color: $color-key;
              padding: 0;
              margin: 0;
            }
            #{$this}__anchor {
              @include is-desktop-narrower {
                color: $color-base;
                background-color: $color-key;
                display: block;
                padding: 15px 20px;
              }
              @include is-laptop-wider{
                font-size: 1.1rem;
                padding: 25px 14px 24px;
              }
              &:hover {
                @include is-laptop-wider{
                  background-color: $color-accent;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
  &__nav-button {
    display: none;
    @include is-desktop-narrower {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 60px;
      background-color: $color-key;
      cursor: pointer;
    }
    span {
      position: absolute;
      top: 0;
      left: 18px;
      width: 25px;
      height: 2px;
      background-color: #fff;
      transition: $animation;
      &:nth-child(1){
        top: 21px;
        .is-palmtop-nav-open & {
          top: 28px;
          transform: rotate(45deg);
        }
      }
      &:nth-child(2){
        top: 28px;
        .is-palmtop-nav-open & {
          opacity: 0;
        }
      }
      &:nth-child(3){
        top: 36px;
        .is-palmtop-nav-open & {
          top: 28px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
