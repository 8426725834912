/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700,900&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html {
  color: #000000;
  background-color: #ffffff;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 62.5%;
  font-weight: 400;
  letter-spacing: 0.06em;
  line-height: 1.78em;
}

html.is-palmtop-nav-open {
  overflow: hidden;
}

body {
  font-size: 1.4rem;
}

@media screen and (min-width: 960px) {
  body {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1050px) {
  body {
    font-size: 1.6rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

sub {
  vertical-align: sub;
  font-size: 1.1rem;
}

@media screen and (min-width: 960px) {
  sub {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1050px) {
  sub {
    font-size: 1.1rem;
  }
}

sup {
  vertical-align: super;
  font-size: 1.1rem;
}

@media screen and (min-width: 960px) {
  sup {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1050px) {
  sup {
    font-size: 1.1rem;
  }
}

button {
  outline: none;
  border: none;
  padding: 0;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.break {
  word-wrap: break-word !important;
}

.full {
  box-sizing: border-box !important;
  width: 100% !important;
}

.stick {
  margin: 0 !important;
}

.stick-bottom {
  margin-bottom: 0 !important;
}

.stick-left {
  margin-left: 0 !important;
}

.stick-right {
  margin-right: 0 !important;
}

.stick-top {
  margin-top: 0 !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-lower {
  text-transform: lowercase;
}

.text-upper {
  text-transform: uppercase;
}

.word {
  display: inline-block;
}

.pc-only {
  display: block;
}

@media print, screen and (max-width: 760px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}

@media print, screen and (max-width: 760px) {
  .sp-only {
    display: block;
  }
}

.breadcrumb {
  margin-top: 15px;
}

.breadcrumb__inner {
  display: inline-block;
  background-color: #ffffff;
  padding: 5px 10px;
}

.breadcrumb__inner__list {
  display: flex;
}

.breadcrumb__inner__list__item {
  align-items: center;
}

.breadcrumb__inner__list__item__anchor,
.breadcrumb__inner__list__item span {
  color: #000000;
  font-size: 1.2rem;
  line-height: 1;
  display: block;
}

.breadcrumb__inner__list__item:first-child {
  position: relative;
  padding-left: 20px;
}

.breadcrumb__inner__list__item:first-child::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-image: url("../../assets/images/common/ico_breadcrumb_home.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.breadcrumb__inner__list__item:not(:first-child) {
  position: relative;
  padding-left: 20px;
  margin-left: 15px;
}

.breadcrumb__inner__list__item:not(:first-child)::before {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  background-image: url("../../assets/images/common/ico_arrow_key.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 2px;
  left: 0;
}

.button {
  max-width: 315px;
  margin-left: auto;
  margin-right: auto;
}

.button:not(:first-child) {
  margin-top: 30px;
}

@media screen and (min-width: 960px) {
  .button:not(:first-child) {
    margin-top: 60px;
  }
}

.button:not(:last-child) {
  margin-bottom: 0;
}

@media screen and (min-width: 960px) {
  .button {
    max-width: 400px;
  }
}

.button--line::before {
  display: block;
  content: '';
  height: 44px;
  width: 2px;
  margin-left: auto;
  margin-right: auto;
  background-color: #4b9fe3;
  border-radius: 1px;
  margin-bottom: 9px;
  transition: all 0.2s;
}

@media screen and (min-width: 960px) {
  .button--line:hover::before {
    background-color: #2e388d;
  }
}

.button__anchor {
  display: block;
  outline: none;
  border: none;
  color: #ffffff;
  background-color: #4b9fe3;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: .12em;
  line-height: 1;
  padding: 20px 20px 21px;
  border-radius: 30px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

@media screen and (min-width: 960px) {
  .button__anchor:hover {
    background-color: #2e388d;
  }
}

.button__anchor__icon {
  display: inline-block;
  position: relative;
  padding-left: 20px;
}

.button__anchor__icon::before {
  content: '';
  width: 6px;
  height: 11px;
  background-image: url("../../assets/images/common/ico_arrow_white.svg");
  background-repeat: no-repeat;
  background-size: 6px 11px;
  display: block;
  position: absolute;
  top: calc(50% + 1px);
  left: 0;
  transform: translateY(-50%);
}

@media screen and (min-width: 960px) {
  .button__anchor__icon::before {
    background-size: 7px 12px;
    width: 7px;
    height: 12px;
  }
}

.button__anchor__icon--window {
  padding-right: 18px;
}

.button__anchor__icon--window::after {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  content: '';
  background-image: url("../../assets/images/common/ico_window_white.svg");
  background-size: 10px 8px;
  background-repeat: no-repeat;
  width: 10px;
  height: 8px;
  transform: translateY(-50%);
}

.columns:not(:first-child) {
  margin-top: 30px;
}

.columns:not(:last-child) {
  margin-bottom: 30px;
}

@media screen and (min-width: 960px) {
  .columns {
    display: flex;
  }
}

@media screen and (min-width: 960px) {
  .columns__column {
    flex: 1;
  }
}

.columns__column:not(:first-child) {
  margin-top: 30px;
}

@media screen and (min-width: 960px) {
  .columns__column:not(:first-child) {
    margin-top: 0;
    margin-left: 15px;
  }
}

.columns__column:not(:last-child) {
  margin-bottom: 30px;
}

@media screen and (min-width: 960px) {
  .columns__column:not(:last-child) {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (min-width: 960px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (min-width: 960px) {
  .container {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.container--full {
  max-width: none;
}

.container--wide {
  max-width: 1380px;
}

.container--narrow {
  max-width: 960px;
}

.container--narrow-more {
  max-width: 640px;
}

.figure img {
  width: 100%;
  vertical-align: middle;
}

.form__list__item:not(:first-child) {
  margin-top: 40px;
}

@media screen and (min-width: 640px) {
  .form__list__item:not(:first-child) {
    margin-top: 50px;
  }
}

.form__list__item--division {
  padding-top: 50px;
  background-image: url("../../assets/images/common/bg_footer_above_sub_heading.png");
  background-repeat: repeat-x;
}

@media screen and (min-width: 640px) {
  .form__columns {
    display: flex;
    margin-left: -18px;
    margin-right: -18px;
  }
}

@media screen and (min-width: 640px) {
  .form__columns__column {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.form__columns__column:not(:first-child) {
  margin-top: 40px;
}

@media screen and (min-width: 640px) {
  .form__columns__column:not(:first-child) {
    margin-top: 0;
  }
}

.form__heading {
  font-size: 1.6rem;
  letter-spacing: .15em;
}

.form__heading__sub {
  font-size: 1.2rem;
  display: inline-block;
}

.form__heading--division {
  background-image: url("../../assets/images/common/bg_footer_above_sub_heading.png");
  background-repeat: repeat-x;
  background-position: 0 50%;
  margin-bottom: 40px;
}

.form__heading--division span {
  padding-right: 20px;
  background-color: #f1f9fd;
}

.form__required {
  color: #ffffff;
  font-size: 1.1rem;
  letter-spacing: .15em;
  background-color: #4b9fe3;
  padding: 3px;
  display: inline-block;
  margin-left: 3px;
}

.form__radio {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: -10px;
  margin-right: -10px;
}

.form__radio .mwform-radio-field {
  width: calc(100% / 2);
  padding-left: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-left: 0 !important;
}

.form__radio .mwform-radio-field:nth-child(n+3) {
  margin-top: 25px;
}

@media screen and (min-width: 640px) {
  .form__radio .mwform-radio-field:nth-child(n+3) {
    margin-top: 0;
  }
}

@media screen and (min-width: 640px) {
  .form__radio .mwform-radio-field:nth-child(n+4) {
    margin-top: 25px;
  }
}

@media screen and (min-width: 640px) {
  .form__radio .mwform-radio-field {
    width: calc(100% / 3);
  }
}

.form__radio__input {
  display: none;
}

.form__radio__input:checked + .mwform-radio-field-text::after {
  opacity: 1;
  pointer-events: auto;
}

.form__radio .mwform-radio-field-text {
  position: relative;
  padding-left: 35px;
  font-size: 1.5rem;
  display: block;
  line-height: 1.2em;
}

.form__radio .mwform-radio-field-text::before {
  content: '';
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #4b9fe3;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff;
  position: absolute;
  top: -3px;
  left: 0;
}

.form__radio .mwform-radio-field-text::after {
  content: '';
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 7px solid #4b9fe3;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff;
  position: absolute;
  top: -3px;
  left: 0;
  transition: all .2s;
  opacity: 0;
  pointer-events: none;
}

.form__checkbox {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: -10px;
  margin-right: -10px;
}

.form__checkbox .mwform-checkbox-field {
  width: calc(100% / 2);
  padding-left: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-left: 0 !important;
}

.form__checkbox .mwform-checkbox-field:nth-child(n+3) {
  margin-top: 25px;
}

@media screen and (min-width: 640px) {
  .form__checkbox .mwform-checkbox-field:nth-child(n+3) {
    margin-top: 0;
  }
}

@media screen and (min-width: 640px) {
  .form__checkbox .mwform-checkbox-field:nth-child(n+4) {
    margin-top: 25px;
  }
}

@media screen and (min-width: 640px) {
  .form__checkbox .mwform-checkbox-field {
    width: calc(100% / 3);
  }
}

.form__checkbox__input {
  display: none;
}

.form__checkbox__input:checked + .mwform-checkbox-field-text::after {
  opacity: 1;
  pointer-events: auto;
}

.form__checkbox .mwform-checkbox-field-text {
  position: relative;
  padding-left: 40px;
  font-size: 1.5rem;
  display: block;
  line-height: 1.2em;
}

.form__checkbox .mwform-checkbox-field-text::before {
  content: '';
  width: 25px;
  height: 25px;
  border: 1px solid #4b9fe3;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff;
  position: absolute;
  top: -3px;
  left: 0;
  border-radius: 5px;
}

.form__checkbox .mwform-checkbox-field-text::after {
  content: '';
  width: 18px;
  height: 18px;
  background-image: url("../../assets/images/common/ico_check.svg");
  background-size: cover;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: all .2s;
  opacity: 0;
  pointer-events: none;
}

.form__field {
  margin-top: 15px;
}

.form__field__input {
  background-color: #fff;
  border: 1px solid #4b9fe3;
  border-radius: 5px;
  padding: 12px 20px 12px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
}

.form__field__select-arrow {
  position: relative;
}

.form__field__select-arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  display: block;
  background-image: url("../images/common/ico_arrow_down_key.svg");
  background-size: 10px 8px;
  background-repeat: no-repeat;
  width: 10px;
  height: 8px;
  transform: translateY(-50%);
}

.form__field__select {
  width: 130px;
  background-color: #fff;
  border: 1px solid #4b9fe3;
  border-radius: 5px;
  padding: 12px 20px 12px;
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  appearance: none;
}

.form__field__select:focus {
  outline-color: #2e388d;
}

.form__textarea {
  background-color: #fff;
  border: 1px solid #4b9fe3;
  border-radius: 5px;
  padding: 12px 20px 12px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
}

.form__tel {
  display: flex;
  align-items: center;
}

.form__tel__item__hyphen {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.3rem;
}

.form__tel__item .form__field__input {
  max-width: 80px;
}

@media screen and (min-width: 640px) {
  .form__tel__item .form__field__input {
    max-width: 129px;
  }
}

.form__content__catalog__menu {
  display: block;
  margin-top: -25px;
}

.form__content__catalog__menu__group:not(:first-child) {
  margin-top: 12px;
}

.form__content__catalog__menu__group .mwform-radio-field {
  width: auto;
  display: inline-block;
  margin-right: -30px;
}

.form__content__catalog__menu__group__note {
  display: inline-block;
  padding-top: 10px;
  margin-left: 45px;
  line-height: 1.92em;
}

.form__content__catalog__list {
  margin-top: 37px;
}

.form__content__catalog__list__item {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 960px) {
  .form__content__catalog__list__item {
    position: relative;
    display: block;
  }
}

.form__content__catalog__list__item:not(:first-child) {
  margin-top: 20px;
}

@media screen and (min-width: 960px) {
  .form__content__catalog__list__item:not(:first-child) {
    margin-top: 30px;
  }
}

.form__content__catalog__list__item:nth-child(1) {
  min-height: 184px;
}

.form__content__catalog__list__item:nth-child(2) {
  min-height: 185px;
}

.form__content__catalog__list__item:nth-child(3) {
  min-height: 157px;
}

.form__content__catalog__list__item__checkbox {
  width: 100%;
  margin-top: 0;
}

@media screen and (min-width: 960px) {
  .form__content__catalog__list__item__checkbox {
    padding-left: 130px;
    margin-left: 36px;
  }
}

.form__content__catalog__list__item__checkbox .mwform-checkbox-field {
  width: 100%;
}

@media screen and (min-width: 960px) {
  .form__content__catalog__list__item__checkbox .mwform-checkbox-field {
    padding-left: 0;
  }
}

.form__content__catalog__list__item__image {
  max-width: 130px;
  margin-top: 20px;
}

@media screen and (min-width: 960px) {
  .form__content__catalog__list__item__image {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
  }
}

.form__content__catalog__list__item__paragraph {
  width: calc(100% - 130px);
  padding-left: 20px;
  box-sizing: border-box;
}

@media screen and (min-width: 960px) {
  .form__content__catalog__list__item__paragraph {
    width: 100%;
    padding-left: 130px;
    margin-left: 36px;
  }
}

.form__privacy {
  margin-top: 40px;
  padding-bottom: 20px;
  background-image: url("../../assets/images/common/bg_dot_line.png");
  background-repeat: repeat-x;
  background-position: center bottom;
}

.form__privacy__anchor {
  text-align: right;
}

.form__privacy__anchor__link {
  font-size: 1.3rem;
  letter-spacing: .15em;
  color: #4b9fe3;
  position: relative;
  padding-left: 15px;
}

.form__privacy__anchor__link::before {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  background-image: url("../../assets/images/common/ico_arrow_key.svg");
  width: 5px;
  height: 10px;
  background-size: 5px 10px;
}

.form .error {
  color: #ea5655 !important;
  margin-top: 10px !important;
  font-size: 1.6rem !important;
}

.form .mwform-tel-field input[type="text"], .form .mwform-tel-field .mwform-zip-field input[type="text"] {
  display: inline-block;
  width: auto;
}

.form .mwform-tel-field__separator {
  font-size: 1.3rem;
  margin-left: 5px;
  margin-right: 5px;
}

.form .mwform-zip-field__separator {
  font-size: 1.3rem;
  margin-left: 5px;
  margin-right: 5px;
}

.hero {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url("../../assets/images/privacy/bg_hero.png");
}

@media screen and (min-width: 960px) {
  .hero {
    height: 300px;
  }
}

.hero__heading {
  font-size: 2.5rem;
  letter-spacing: .3em;
  line-height: 1.2em;
  font-weight: 500;
  text-align: center;
  color: #4b9fe3;
}

@media screen and (min-width: 960px) {
  .hero__heading {
    font-size: 3.7rem;
  }
}

.hero__heading::after {
  content: attr(data-en-text);
  display: block;
  margin-top: 8px;
  font-size: 1.5rem;
  letter-spacing: .06em;
  font-family: "Odibee Sans", sans-serif;
}

@media screen and (min-width: 960px) {
  .hero__heading::after {
    font-size: 2.5rem;
    margin-top: 13px;
  }
}

.paragraph {
  font-size: 1.3rem;
  letter-spacing: .16em;
  line-height: 1.92em;
}

@media screen and (min-width: 960px) {
  .paragraph {
    font-size: 1.4rem;
    line-height: 2em;
    letter-spacing: .15em;
  }
}

.paragraph:not(:first-child) {
  margin-top: 15px;
}

@media screen and (min-width: 960px) {
  .paragraph:not(:first-child) {
    margin-top: 20px;
  }
}

.paragraph:not(:last-child) {
  margin-bottom: 15px;
}

@media screen and (min-width: 960px) {
  .paragraph:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 960px) {
  .paragraph--bigger {
    font-size: 1.5rem;
  }
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.section:first-child {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .section:first-child {
    padding-top: 60px;
    padding-bottom: 100px;
  }
}

.section--colored {
  background-color: #f1f9fd;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .section--colored {
    padding-top: 60px;
    padding-bottom: 100px;
  }
}

.content {
  display: block;
  background-image: url("../../assets/images/common/bg_dot.png");
  padding-top: 60px;
  overflow: hidden;
}

@media screen and (min-width: 960px) {
  .content {
    padding-top: 0;
  }
}

.content--colored {
  background-image: none;
  background-color: #f1f9fd;
}

.footer__above {
  padding-top: 35px;
  padding-bottom: 30px;
}

@media screen and (min-width: 960px) {
  .footer__above__main__columns {
    display: flex;
    justify-content: space-between;
  }
}

.footer__above__main__logo {
  width: 214px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 960px) {
  .footer__above__main__logo {
    margin-left: 0;
  }
}

.footer__above__main__logo img {
  max-width: 100%;
}

.footer__above__main__address {
  font-size: 1.2rem;
  letter-spacing: .12em;
  line-height: 1.81em;
  text-align: center;
  margin-top: 20px;
}

@media screen and (min-width: 960px) {
  .footer__above__main__address {
    text-align: left;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 960px) {
  .footer__above__main__address__tel {
    pointer-events: none;
  }
}

.footer__above__main__button {
  margin-top: 35px;
  max-width: 197px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 960px) {
  .footer__above__main__button {
    max-width: 206px;
    margin-top: 0;
    margin-right: 0;
  }
}

.footer__above__main__button__anchor {
  text-align: center;
  display: block;
  color: #ffffff;
  background-color: #4b9fe3;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: .12em;
  padding: 12px 20px 11px;
  border-radius: 30px;
  transition: all 0.2s;
}

@media screen and (min-width: 960px) {
  .footer__above__main__button__anchor:hover {
    background-color: #2e388d;
  }
}

.footer__above__main__button__anchor__icon {
  display: inline-block;
  position: relative;
  padding-left: 12px;
}

.footer__above__main__button__anchor__icon::before {
  content: '';
  width: 6px;
  height: 11px;
  background-image: url("../../assets/images/common/ico_arrow_white.svg");
  background-repeat: no-repeat;
  background-size: 6px 11px;
  display: block;
  position: absolute;
  top: calc(50% + 1px);
  left: 0;
  transform: translateY(-50%);
}

.footer__above__main__nav {
  margin-top: 15px;
  text-align: center;
}

@media screen and (min-width: 960px) {
  .footer__above__main__nav {
    margin-top: 45px;
  }
}

@media screen and (min-width: 960px) {
  .footer__above__main__nav__list {
    display: flex;
  }
}

.footer__above__main__nav__list__item:not(:first-child) {
  margin-top: 25px;
}

@media screen and (min-width: 960px) {
  .footer__above__main__nav__list__item:not(:first-child) {
    margin-top: 0;
    margin-left: 30px;
  }
}

.footer__above__main__nav__list__item__anchor {
  font-size: 1.3rem;
  padding-left: 12px;
  position: relative;
  transition: all 0.2s;
}

@media screen and (min-width: 960px) {
  .footer__above__main__nav__list__item__anchor:hover {
    color: #4b9fe3;
  }
}

.footer__above__main__nav__list__item__anchor::before {
  content: '';
  width: 6px;
  height: 11px;
  background-image: url("../../assets/images/common/ico_arrow_key.svg");
  background-repeat: no-repeat;
  background-size: 6px 11px;
  display: block;
  position: absolute;
  top: calc(50% + 1px);
  left: 0;
  transform: translateY(-50%);
}

@media screen and (min-width: 960px) {
  .footer__above__main__nav__list__item__anchor::before {
    top: 50%;
    width: 7px;
    height: 12px;
    background-size: 7px 12px;
  }
}

.footer__above__sub {
  margin-top: 50px;
}

@media screen and (min-width: 960px) {
  .footer__above__sub {
    margin-top: 60px;
  }
}

.footer__above__sub__heading {
  font-size: 1.2rem;
  letter-spacing: .12em;
  background-image: url("../../assets/images/common/bg_footer_above_sub_heading.png");
  background-repeat: repeat-x;
  background-position: 0 50%;
}

@media screen and (min-width: 960px) {
  .footer__above__sub__heading {
    font-size: 1.3rem;
  }
}

.footer__above__sub__heading__inner {
  padding-right: 10px;
  background-color: #ffffff;
}

.footer__above__sub__nav {
  margin-top: 30px;
}

@media screen and (min-width: 960px) {
  .footer__above__sub__nav__list {
    display: flex;
  }
}

.footer__above__sub__nav__list__item__anchor {
  padding-left: 12px;
  padding-right: 15px;
  position: relative;
  transition: all 0.2s;
}

@media screen and (min-width: 960px) {
  .footer__above__sub__nav__list__item__anchor:hover {
    color: #4b9fe3;
  }
}

.footer__above__sub__nav__list__item__anchor::before {
  content: '';
  width: 6px;
  height: 11px;
  background-image: url("../../assets/images/common/ico_arrow_key.svg");
  background-repeat: no-repeat;
  background-size: 6px 11px;
  display: block;
  position: absolute;
  top: calc(50% + 1px);
  left: 0;
  transform: translateY(-50%);
}

.footer__above__sub__nav__list__item__anchor--window::after {
  position: absolute;
  top: calc(50% + 1px);
  right: 0;
  display: block;
  content: '';
  background-image: url("../../assets/images/common/ico_window.svg");
  background-size: 10px 8px;
  background-repeat: no-repeat;
  width: 10px;
  height: 8px;
  transform: translateY(-50%);
}

@media screen and (min-width: 960px) {
  .footer__above__sub__nav__list__item:nth-child(1) {
    margin-right: auto;
  }
}

.footer__above__sub__nav__list__item:nth-child(1) .footer__above__sub__nav__list__item__anchor {
  font-size: 1.4rem;
}

.footer__above__sub__nav__list__item:nth-child(2) {
  margin-top: 30px;
}

@media screen and (min-width: 960px) {
  .footer__above__sub__nav__list__item:nth-child(2) {
    margin-top: 0;
  }
}

.footer__above__sub__nav__list__item:nth-child(n+3) {
  margin-top: 20px;
}

@media screen and (min-width: 960px) {
  .footer__above__sub__nav__list__item:nth-child(n+3) {
    margin-top: 0;
    margin-left: 30px;
  }
}

.footer__above__sub__nav__list__item__anchor {
  font-size: 1.3rem;
}

.footer__below {
  background-color: #f5f5f5;
  padding: 21px 0 20px;
}

@media screen and (min-width: 960px) {
  .footer__below {
    padding: 25px 0 28px;
  }
}

.footer__below__inner {
  display: flex;
  align-items: flex-end;
}

@media screen and (min-width: 960px) {
  .footer__below__inner {
    justify-content: flex-end;
  }
}

.footer__below__inner__nav__list__item__anchor {
  color: #737373;
  font-size: 1.1rem;
  padding-left: 12px;
  position: relative;
  transition: all 0.2s;
}

@media screen and (min-width: 960px) {
  .footer__below__inner__nav__list__item__anchor:hover {
    color: #4b9fe3;
  }
}

.footer__below__inner__nav__list__item__anchor::before {
  content: '';
  width: 6px;
  height: 11px;
  background-image: url("../../assets/images/common/ico_arrow_key.svg");
  background-repeat: no-repeat;
  background-size: 6px 11px;
  display: block;
  position: absolute;
  top: calc(50% + 1px);
  left: 0;
  transform: translateY(-50%);
}

.footer__below__inner__copyright {
  margin-left: 20px;
  color: #737373;
  font-size: 1.1rem;
}

@media screen and (min-width: 960px) {
  .footer__below__inner__copyright {
    margin-left: 40px;
  }
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 959px) {
  .header {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 960px) {
  .header {
    height: auto;
    display: block;
  }
}

.header__above {
  display: none;
}

@media screen and (min-width: 960px) {
  .header__above {
    display: block;
    background-color: #f5f5f5;
  }
}

.header__above__extra-nav {
  display: flex;
  justify-content: flex-end;
}

.header__above__extra-nav__list {
  display: flex;
}

@media screen and (max-width: 959px) {
  .header__above__extra-nav__list {
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -8px;
    margin-right: -8px;
  }
}

@media screen and (min-width: 960px) {
  .header__above__extra-nav__list {
    border-right: 1px solid #dfdfdf;
  }
}

@media screen and (max-width: 959px) {
  .header__above__extra-nav__list__item {
    width: 50%;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }
  .header__above__extra-nav__list__item:nth-child(n+3) {
    margin-top: 15px;
  }
}

@media screen and (min-width: 960px) {
  .header__above__extra-nav__list__item {
    border-left: 1px solid #dfdfdf;
  }
}

.header__above__extra-nav__list__item__anchor {
  display: block;
  font-size: 1.1rem;
  transition: all 0.2s;
  padding: 15px 11px;
}

@media screen and (max-width: 959px) {
  .header__above__extra-nav__list__item__anchor {
    background-color: #fff;
  }
}

@media screen and (min-width: 960px) {
  .header__above__extra-nav__list__item__anchor {
    padding: 20px 11px;
  }
}

.is-current .header__above__extra-nav__list__item__anchor {
  color: #4b9fe3;
}

@media screen and (min-width: 960px) {
  .header__above__extra-nav__list__item__anchor:hover {
    background-color: #dfdfdf;
  }
}

.header__above__extra-nav__list__item__anchor__icon {
  display: block;
  position: relative;
  padding-right: 15px;
}

.header__above__extra-nav__list__item__anchor__icon::after {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  content: '';
  background-image: url("../../assets/images/common/ico_window.svg");
  background-size: 10px 8px;
  background-repeat: no-repeat;
  width: 10px;
  height: 8px;
  transform: translateY(-50%);
}

.is-current .header__above__extra-nav__list__item__anchor__icon::after {
  background-image: url("../../assets/images/common/ico_arrow_down_key.svg");
}

.header__below__logo {
  width: 214px;
}

@media screen and (max-width: 959px) {
  .header__below__logo {
    position: fixed;
    top: 10px;
    left: 15px;
  }
}

.header__below__logo__anchor img {
  max-width: 100%;
}

@media screen and (min-width: 960px) {
  .header__below__columns {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 960px) {
  .header__below__columns__column {
    display: flex;
    align-items: center;
  }
}

.is-palmtop-nav-open .header__below__nav {
  transform: translateX(0);
}

@media screen and (max-width: 959px) {
  .header__below__nav {
    transform: translateX(100%);
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #F5F5F5;
    box-sizing: border-box;
    padding: 20px 0;
    transition: all 0.2s;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav {
    display: flex;
  }
}

@media screen and (max-width: 959px) {
  .header__below__nav__list {
    margin-top: 40px;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav__list {
    display: flex;
  }
}

@media screen and (max-width: 959px) {
  .header__below__nav__list__item {
    text-align: center;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav__list__item {
    display: flex;
    align-items: flex-end;
    margin: 0 30px 0 27px;
    position: relative;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav__list__item:not(:first-child):not(:last-child):before {
    position: absolute;
    bottom: 10px;
    left: -30px;
    content: '/';
    display: block;
    font-size: 1.3rem;
    color: #737373;
  }
}

.header__below__nav__list__item__anchor {
  display: block;
  position: relative;
}

@media screen and (max-width: 959px) {
  .header__below__nav__list__item__anchor {
    font-size: 1.5rem;
    padding: 20px 0;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav__list__item__anchor {
    display: block;
    font-size: 1.3rem;
    padding-bottom: 10px;
    transition: all 0.2s;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav__list__item__anchor:hover {
    color: #4b9fe3;
  }
}

.header__below__nav__list__item__anchor__inner {
  position: relative;
  display: inline-block;
}

@media screen and (max-width: 959px) {
  .header__below__nav__list__item__anchor__inner {
    padding-bottom: 10px;
  }
}

.is-current .header__below__nav__list__item__anchor__inner::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #4b9fe3;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 1;
}

@media screen and (min-width: 1050px) {
  .is-current .header__below__nav__list__item__anchor__inner::after {
    bottom: -10px;
  }
}

@media screen and (max-width: 959px) {
  .header__below__nav__list__item[data-category="contact"] {
    padding: 0 20px;
    margin-top: 20px;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav__list__item[data-category="contact"] {
    color: #ffffff;
    background-color: #4b9fe3;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 959px) {
  .header__below__nav__list__item[data-category="contact"] .header__below__nav__list__item__anchor {
    color: #ffffff;
    background-color: #4b9fe3;
    display: block;
    padding: 15px 20px;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav__list__item[data-category="contact"] .header__below__nav__list__item__anchor {
    font-size: 1.1rem;
    padding: 25px 14px 24px;
  }
}

@media screen and (min-width: 960px) {
  .header__below__nav__list__item[data-category="contact"] .header__below__nav__list__item__anchor:hover {
    background-color: #2e388d;
    color: #ffffff;
  }
}

.header__nav-button {
  display: none;
}

@media screen and (max-width: 959px) {
  .header__nav-button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background-color: #4b9fe3;
    cursor: pointer;
  }
}

.header__nav-button span {
  position: absolute;
  top: 0;
  left: 18px;
  width: 25px;
  height: 2px;
  background-color: #fff;
  transition: all 0.2s;
}

.header__nav-button span:nth-child(1) {
  top: 21px;
}

.is-palmtop-nav-open .header__nav-button span:nth-child(1) {
  top: 28px;
  transform: rotate(45deg);
}

.header__nav-button span:nth-child(2) {
  top: 28px;
}

.is-palmtop-nav-open .header__nav-button span:nth-child(2) {
  opacity: 0;
}

.header__nav-button span:nth-child(3) {
  top: 36px;
}

.is-palmtop-nav-open .header__nav-button span:nth-child(3) {
  top: 28px;
  transform: rotate(-45deg);
}

.aside__contact {
  padding: 50px 0;
  background-image: url("../../assets/images/common/bg_contact.jpg");
  background-size: cover;
  background-position: center;
}

@media screen and (min-width: 960px) {
  .aside__contact {
    padding: 85px 0 90px;
  }
}

.aside__contact__heading {
  font-size: 2.0rem;
  letter-spacing: .3em;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 960px) {
  .aside__contact__heading {
    font-size: 2.8rem;
  }
}

.aside__contact__heading::after {
  content: attr(data-en-text);
  display: block;
  margin-top: 8px;
  font-size: 1.4rem;
  letter-spacing: .06em;
  font-family: "Odibee Sans", sans-serif;
}

@media screen and (min-width: 960px) {
  .aside__contact__heading::after {
    font-size: 1.4rem;
    margin-top: 13px;
  }
}

.aside__contact__paragraph {
  font-size: 1.3rem;
  letter-spacing: .16em;
  line-height: 1.92em;
  color: #ffffff;
  margin-top: 20px;
  text-align: center;
}

@media screen and (min-width: 960px) {
  .aside__contact__paragraph {
    font-size: 1.4rem;
    margin-top: 40px;
  }
}
