@charset "UTF-8";

//
// ボタン
//

.button {
  &:not(:first-child){
    margin-top: 30px;
    @include is-laptop-wider {
      margin-top: 60px;
    }
  }
  &:not(:last-child){
    margin-bottom: 0;
  }
  max-width: 315px;
  margin-left: auto;
  margin-right: auto;
  @include is-laptop-wider{
    max-width: 400px;
  }
  &--line {
    &::before {
      display: block;
      content: '';
      height: 44px;
      width: 2px;
      margin-left: auto;
      margin-right: auto;
      background-color: $color-key;
      border-radius: 1px;
      margin-bottom: 9px;
      transition: $animation;
    }
    &:hover {
      &::before {
        @include is-laptop-wider {
          background-color: $color-accent;
        }
      }
    }
  }
  &__anchor {
    display: block;
    outline: none;
    border: none;
    color: #ffffff;
    background-color: $color-key;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: .12em;
    line-height: 1;
    padding: 20px 20px 21px;
    border-radius: 30px;
    width: 100%;
    box-sizing: border-box;
    font-family: $font-main;
    text-align: center;
    cursor: pointer;
    transition: $animation;
    &:hover {
      @include is-laptop-wider{
        background-color: $color-accent;
      }
    }
    &__icon {
      display: inline-block;
      position: relative;
      padding-left: 20px;
      &::before {
        content: '';
        width: 6px;
        height: 11px;
        background-image: url('../../assets/images/common/ico_arrow_white.svg');
        background-repeat: no-repeat;
        background-size: 6px 11px;
        display: block;
        position: absolute;
        top: calc(50% + 1px);
        left: 0;
        transform: translateY(-50%);
        @include is-laptop-wider {
          background-size: 7px 12px;
          width: 7px;
          height: 12px;
        }
      }
      &--window {
        padding-right: 18px;
        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          display: block;
          content: '';
          background-image: url('../../assets/images/common/ico_window_white.svg');
          background-size: 10px 8px;
          background-repeat: no-repeat;
          width: 10px;
          height: 8px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
