@charset "UTF-8";

//
// コンテンツ幅
//

.container {
  max-width: $container-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-right: $container-padded-width-palmtop;
  padding-left: $container-padded-width-palmtop;
  @include is-laptop-wider {
    padding-right: $container-padded-width-laptop;
    padding-left: $container-padded-width-laptop;
  }
  @include is-laptop-wider{
    padding-right: $container-padded-width-desktop;
    padding-left: $container-padded-width-desktop;
  }
  &--full {
    max-width: none;
  }
  &--wide {
    max-width: 1380px;
  }
  &--narrow {
    max-width: 960px;
  }
  &--narrow-more {
    max-width: 640px;
  }
}
