@charset "UTF-8";

//
// サイト全体で共通の要素のスタイル
//

html {
  color: $color-main;
  background-color: $color-base;
  font-family: $font-root;
  font-size: $font-size-root-initialize;
  font-weight: $font-weight-root;
  letter-spacing: 0.06em;
  line-height: 1.78em;
  &.is-palmtop-nav-open {
    overflow: hidden;
  }
}

body {
  font-size: $font-size-root-palmtop;
  @include is-laptop-wider {
    font-size: $font-size-root-laptop;
  }
  @include is-desktop-wider{
    font-size: $font-size-root-desktop;
  }
}


head {
}

title {
}

base {
}

link {
}

meta {
}

style {
}

body {
}

article {
}

section {
}

nav {
}

aside {
}

h1 {
  .natural & {

  }
}

h2 {
  .natural & {

  }
}

h3 {
  .natural & {

  }
}

h4 {
  .natural & {
  }
}

h5 {
  .natural & {
  }
}

h6 {
  .natural & {
  }
}

header {
}

footer {
}

address {
}

p {
  .natural & {

  }
}

hr {
  .natural & {

  }
}

pre {
  .natural & {

  }
}

blockquote {
  .natural & {

  }
}

ol,
ul {
  .natural & {

  }
}

ol {
}

ul {
}

li {
  .natural & {

  }
}

dl {
  .natural & {

  }
}

dt {
  .natural & {

  }
}

dd {
  .natural & {

  }
}

figure {
  .natural & {

  }
}

figcaption {
  .natural figure & {

  }
}

div {
}

main {
}

a {
  color: inherit;
  text-decoration: none;
}

em {
}

strong {
}

small {

}

s {
}

cite {
}

q {
}

dfn {
}

abbr {
}

data {
}

time {
}

code {
}

var {
}

samp {
}

kbd {
}

sub {
  vertical-align: sub;
  font-size:$font-size-inferior-palmtop;
  @include is-laptop-wider {
    font-size:$font-size-inferior-laptop;
  }
  @include is-desktop-wider{
    font-size: $font-size-inferior-desktop;
  }
}

sup {
  vertical-align: super;
  font-size:$font-size-inferior-palmtop;
  @include is-laptop-wider {
    font-size:$font-size-inferior-laptop;
  }
  @include is-desktop-wider{
    font-size: $font-size-inferior-desktop;
  }
}

i {
}

b {
}

u {
}

mark {
}

ruby {
}

rb {
}

rt {
}

rtc {
}

rp {
}

bdi {
}

bdo {
}

span {
}

br {
}

wbr {
}

ins {
}

del {
}

img {
}

iframe {
}

embed {
}

object {
}

param {
}

video {
}

audio {
}

source {
}

track {
}

map {
}

area {
}

svg {
}

table {
  .natural & {

  }
}

caption {
  .natural & {

  }
}

colgroup {

}

col {
  .natural & {
  }
}

tbody {
  .natural & {
  }
}

thead {
  .natural & {
  }
}

tfoot {
  .natural & {
  }
}

tr {
  .natural & {
  }
}

td {
  .natural & {

  }
}

th {
  .natural & {

  }
}

form {
}

label {
}

input {
  &[type="text"] {
  }

  &[type="search"] {
  }

  &[type="tel"] {
  }

  &[type="url"] {
  }

  &[type="email"] {
  }

  &[type="password"] {
  }

  &[type="data"] {
  }

  &[type="time"] {
  }

  &[type="number"] {
  }

  &[type="range"] {
  }

  &[type="color"] {
  }

  &[type="checkbox"] {
  }

  &[type="radio"] {
  }

  &[type="file"] {
  }

  &[type="submit"] {
  }

  &[type="image"] {
  }

  &[type="reset"] {
  }

  &[type="button"] {
  }
}

button {
  outline: none;
  border: none;
  padding: 0;
}

select {
}

datalist {
}

optgroup {
}

option {
}

textarea {
}

keygen {
}

output {
}

progress {
}

meter {
}

fieldset {
}

legend {
}

script {
}

noscript {
}

template {
}

canvas {
}
