@charset "UTF-8";

//
// パンくず
//

.breadcrumb {
  margin-top: 15px;
  &__inner {
    display: inline-block;
    background-color: #ffffff;
    padding: 5px 10px;
    &__list {
      display: flex;
      &__item {
        align-items: center;
        &__anchor,
        span {
          color: #000000;
          font-size: 1.2rem;
          line-height: 1;
          display: block;
        }
        &:first-child{
          position: relative;
          padding-left: 20px;
          &::before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background-image: url('../../assets/images/common/ico_breadcrumb_home.svg');
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        &:not(:first-child){
          position: relative;
          padding-left: 20px;
          margin-left: 15px;
          &::before {
            content: '';
            display: block;
            width: 5px;
            height: 10px;
            background-image: url('../../assets/images/common/ico_arrow_key.svg');
            background-repeat: no-repeat;
            position: absolute;
            top: 2px;
            left: 0;
          }
        }
      }
    }
  }
}
