@charset "UTF-8";

//
// 文章
//

.paragraph {
  font-size: 1.3rem;
  letter-spacing: .16em;
  line-height: 1.92em;
  @include is-laptop-wider {
    font-size: 1.4rem;
    line-height: 2em;
    letter-spacing: .15em;
  }
  &:not(:first-child){
    margin-top: 15px;
    @include is-laptop-wider {
      margin-top: 20px;
    }
  }
  &:not(:last-child){
    margin-bottom: 15px;
    @include is-laptop-wider {
      margin-bottom: 20px;
    }
  }
  &--bigger {
    @include is-laptop-wider {
      font-size: 1.5rem;
    }
  }
}
