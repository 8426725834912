@charset "UTF-8";

//
// サイトフッタ
//

.footer {
  &__above {
    padding-top: 35px;
    padding-bottom: 30px;
    &__main {
      &__columns {
        @include is-laptop-wider{
          display: flex;
          justify-content: space-between;
        }
        &__column {

        }
      }
      &__logo {
        width: 214px;
        margin-left: auto;
        margin-right: auto;
        @include is-laptop-wider{
          margin-left: 0;
        }
        img {
          max-width: 100%;
        }
      }
      &__address {
        font-size: 1.2rem;
        letter-spacing: .12em;
        line-height: 1.81em;
        text-align: center;
        margin-top: 20px;
        @include is-laptop-wider{
          text-align: left;
          font-size: 1.4rem;
        }
        &__tel {
          @include is-laptop-wider {
            pointer-events: none;
          }
        }
      }
      &__button {
        margin-top: 35px;
        max-width: 197px;
        margin-left: auto;
        margin-right: auto;
        @include is-laptop-wider{
          max-width: 206px;
          margin-top: 0;
          margin-right: 0;
        }
        &__anchor {
          text-align: center;
          display: block;
          color: #ffffff;
          background-color: $color-key;
          font-size: 1.3rem;
          font-weight: 700;
          letter-spacing: .12em;
          padding: 12px 20px 11px;
          border-radius: 30px;
          transition: $animation;
          &:hover {
            @include is-laptop-wider{
              background-color: $color-accent;
            }
          }
          &__icon {
            display: inline-block;
            position: relative;
            padding-left: 12px;
            &::before {
              content: '';
              width: 6px;
              height: 11px;
              background-image: url('../../assets/images/common/ico_arrow_white.svg');
              background-repeat: no-repeat;
              background-size: 6px 11px;
              display: block;
              position: absolute;
              top: calc(50% + 1px);
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
      }
      &__nav {
        margin-top: 15px;
        text-align: center;
        @include is-laptop-wider{
          margin-top: 45px;
        }
        &__list {
          @include is-laptop-wider{
            display: flex;
          }
          &__item {
            &:not(:first-child){
              margin-top: 25px;
              @include is-laptop-wider{
                margin-top: 0;
                margin-left: 30px;
              }
            }
            &__anchor {
              font-size: 1.3rem;
              padding-left: 12px;
              position: relative;
              transition: $animation;
              &:hover {
                @include is-laptop-wider{
                  color: $color-key;
                }
              }
              &::before {
                content: '';
                width: 6px;
                height: 11px;
                background-image: url('../../assets/images/common/ico_arrow_key.svg');
                background-repeat: no-repeat;
                background-size: 6px 11px;
                display: block;
                position: absolute;
                top: calc(50% + 1px);
                left: 0;
                transform: translateY(-50%);
                @include is-laptop-wider{
                  top: 50%;
                  width: 7px;
                  height: 12px;
                  background-size: 7px 12px;
                }
              }
            }
          }
        }
      }
    }
    &__sub {
      margin-top: 50px;
      @include is-laptop-wider{
        margin-top: 60px;
      }
      &__heading {
        font-size: 1.2rem;
        letter-spacing: .12em;
        background-image: url('../../assets/images/common/bg_footer_above_sub_heading.png');
        background-repeat: repeat-x;
        background-position: 0 50%;
        @include is-laptop-wider{
          font-size: 1.3rem;
        }
        &__inner {
          padding-right: 10px;
          background-color: #ffffff;
        }
      }
      &__nav {
        margin-top: 30px;
        &__list {
          @include is-laptop-wider{
            display: flex;
          }
          &__item {
            $this: &;
            &__anchor {
              padding-left: 12px;
              padding-right: 15px;
              position: relative;
              transition: $animation;
              &:hover {
                @include is-laptop-wider{
                  color: $color-key;
                }
              }
              &::before {
                content: '';
                width: 6px;
                height: 11px;
                background-image: url('../../assets/images/common/ico_arrow_key.svg');
                background-repeat: no-repeat;
                background-size: 6px 11px;
                display: block;
                position: absolute;
                top: calc(50% + 1px);
                left: 0;
                transform: translateY(-50%);
              }
              &--window {
                &::after {
                  position: absolute;
                  top: calc(50% + 1px);
                  right: 0;
                  display: block;
                  content: '';
                  background-image: url('../../assets/images/common/ico_window.svg');
                  background-size: 10px 8px;
                  background-repeat: no-repeat;
                  width: 10px;
                  height: 8px;
                  transform: translateY(-50%);
                }
              }
            }
            &:nth-child(1) {
              @include is-laptop-wider{
                margin-right: auto;
              }
              #{$this}__anchor {
                font-size: 1.4rem;
              }
            }
            &:nth-child(2){
              margin-top: 30px;
              @include is-laptop-wider{
                margin-top: 0;
              }
            }
            &:nth-child(n+3){
              margin-top: 20px;
              @include is-laptop-wider{
                margin-top: 0;
                margin-left: 30px;
              }
            }
            &__anchor {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
  &__below {
    background-color: #f5f5f5;
    padding: 21px 0 20px;
    @include is-laptop-wider{
      padding: 25px 0 28px;
    }
    &__inner {
      display: flex;
      align-items: flex-end;
      @include is-laptop-wider{
       justify-content: flex-end;
      }
      &__nav {
        &__list {
          &__item {
            &__anchor {
              color: #737373;
              font-size: 1.1rem;
              padding-left: 12px;
              position: relative;
              transition: $animation;
              &:hover {
                @include is-laptop-wider{
                  color: $color-key;
                }
              }
              &::before {
                content: '';
                width: 6px;
                height: 11px;
                background-image: url('../../assets/images/common/ico_arrow_key.svg');
                background-repeat: no-repeat;
                background-size: 6px 11px;
                display: block;
                position: absolute;
                top: calc(50% + 1px);
                left: 0;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      &__copyright {
        margin-left: 20px;
        color: #737373;
        font-size: 1.1rem;
        @include is-laptop-wider{
          margin-left: 40px;
        }
      }
    }
  }
}
