@charset "UTF-8";

//
// メインコンテンツ
//

.content {
  display: block;
  background-image: url('../../assets/images/common/bg_dot.png');
  padding-top: 60px;
  overflow: hidden;
  @include is-laptop-wider{
    padding-top: 0;
  }
  &--colored {
    background-image: none;
    background-color: $color-sub;
  }
}
