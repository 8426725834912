@charset "UTF-8";

//
// その他のコンテンツ
//

.aside {
  &__contact {
    padding: 50px 0;
    background-image: url('../../assets/images/common/bg_contact.jpg');
    background-size: cover;
    background-position: center;
    @include is-laptop-wider {
      padding: 85px 0 90px;
    }
    &__heading {
      font-size: 2.0rem;
      letter-spacing: .3em;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      position: relative;
      z-index: 1;
      @include is-laptop-wider {
        font-size: 2.8rem;
      }
      &::after {
        content: attr(data-en-text);
        display: block;
        margin-top: 8px;
        font-size: 1.4rem;
        letter-spacing: .06em;
        font-family: $font-english;
        @include is-laptop-wider {
          font-size: 1.4rem;
          margin-top: 13px;
        }
      }
    }
    &__paragraph {
      font-size: 1.3rem;
      letter-spacing: .16em;
      line-height: 1.92em;
      color: #ffffff;
      margin-top: 20px;
      text-align: center;
      @include is-laptop-wider {
        font-size: 1.4rem;
        margin-top: 40px;
      }
    }
  }
}
