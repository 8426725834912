@charset "UTF-8";

//
// フォーム
//

.form {
  &__list {
    &__item {
      &:not(:first-child){
        margin-top: 40px;
        @include is-palmtop-wider {
          margin-top: 50px;
        }
      }
      &--division {
        padding-top: 50px;
        background-image: url('../../assets/images/common/bg_footer_above_sub_heading.png');
        background-repeat: repeat-x;
      }
    }
  }
  &__columns {
    @include is-palmtop-wider {
      display: flex;
      margin-left: -18px;
      margin-right: -18px;
    }
    &__column {
      @include is-palmtop-wider {
        padding-left: 18px;
        padding-right: 18px;
      }
      &:not(:first-child){
        margin-top: 40px;
        @include is-palmtop-wider {
          margin-top: 0;
        }
      }
    }
  }
  &__heading {
    font-size: 1.6rem;
    letter-spacing: .15em;
    &__sub {
      font-size: 1.2rem;
      display: inline-block;
    }
    &--division {
      background-image: url('../../assets/images/common/bg_footer_above_sub_heading.png');
      background-repeat: repeat-x;
      background-position: 0 50%;
      margin-bottom: 40px;
      span {
        padding-right: 20px;
        background-color: $color-sub;
      }
    }
  }
  &__required {
    color: #ffffff;
    font-size: 1.1rem;
    letter-spacing: .15em;
    background-color: $color-key;
    padding: 3px;
    display: inline-block;
    margin-left: 3px;
  }
  &__radio {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-left: -10px;
    margin-right: -10px;
    .mwform-radio-field {
      width: calc(100% / 2);
      padding-left: 10px;
      padding-top: 10px;
      box-sizing: border-box;
      margin-left: 0 !important; //MW WP FORMでつくスタイルを打ち消し
      &:nth-child(n+3){
        margin-top: 25px;
        @include is-palmtop-wider {
          margin-top: 0;
        }
      }
      &:nth-child(n+4){
        @include is-palmtop-wider {
          margin-top: 25px;
        }
      }
      @include is-palmtop-wider {
        width: calc(100% / 3);
      }
    }
    &__input {
      display: none;
      &:checked {
        & + {
          .mwform-radio-field-text {
            &::after {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }
    }
    .mwform-radio-field-text {
      position: relative;
      padding-left: 35px;
      font-size: 1.5rem;
      display: block;
      line-height: 1.2em;
      &::before {
        content: '';
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid $color-key;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: #ffffff;
        position: absolute;
        top: -3px;
        left: 0;
      }
      &::after {
        content: '';
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 7px solid $color-key;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: #ffffff;
        position: absolute;
        top: -3px;
        left: 0;
        transition: all .2s;
        opacity: 0;
        pointer-events: none;
        .form__checkbox__input:checked + & {

        }
      }
    }
  }
  &__checkbox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-left: -10px;
    margin-right: -10px;
    .mwform-checkbox-field {
      width: calc(100% / 2);
      padding-left: 10px;
      padding-top: 10px;
      box-sizing: border-box;
      margin-left: 0 !important; //MW WP FORMでつくスタイルを打ち消し
      &:nth-child(n+3){
        margin-top: 25px;
        @include is-palmtop-wider {
          margin-top: 0;
        }
      }
      &:nth-child(n+4){
        @include is-palmtop-wider {
          margin-top: 25px;
        }
      }
      @include is-palmtop-wider {
        width: calc(100% / 3);
      }
    }
    &__input {
      display: none;
      &:checked {
        & + {
          .mwform-checkbox-field-text {
            &::after {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }
    }
    .mwform-checkbox-field-text {
      position: relative;
      padding-left: 40px;
      font-size: 1.5rem;
      display: block;
      line-height: 1.2em;
      &::before {
        content: '';
        width: 25px;
        height: 25px;
        border: 1px solid $color-key;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: #ffffff;
        position: absolute;
        top: -3px;
        left: 0;
        border-radius: 5px;
      }
      &::after {
        content: '';
        width: 18px;
        height: 18px;
        background-image: url('../../assets/images/common/ico_check.svg');
        background-size: cover;
        position: absolute;
        top: 1px;
        left: 3px;
        transition: all .2s;
        opacity: 0;
        pointer-events: none;
        .form__checkbox__input:checked + & {

        }
      }
    }
  }
  &__field {
    margin-top: 15px;
    &__input {
      background-color: #fff;
      border: 1px solid $color-key;
      border-radius: 5px;
      padding: 12px 20px 12px;
      width: 100%;
      box-sizing: border-box;
      font-family: $font-main;
      font-size: 1.6rem;
    }
    &__select-arrow {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        display: block;
        background-image: url('../images/common/ico_arrow_down_key.svg');
        background-size: 10px 8px;
        background-repeat: no-repeat;
        width: 10px;
        height: 8px;
        transform: translateY(-50%);
      }
    }
    &__select {
      width: 130px;
      background-color: #fff;
      border: 1px solid $color-key;
      border-radius: 5px;
      padding: 12px 20px 12px;
      box-sizing: border-box;
      font-family: $font-main;
      font-size: 1.6rem;
      appearance: none;
      &:focus {
        outline-color: $color-accent;
      }
    }
  }
  &__textarea {
    background-color: #fff;
    border: 1px solid $color-key;
    border-radius: 5px;
    padding: 12px 20px 12px;
    width: 100%;
    box-sizing: border-box;
    font-family: $font-main;
    font-size: 1.6rem;
  }
  &__tel {
    display: flex;
    align-items: center;
    &__item {
      &__hyphen {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 1.3rem;
      }
      .form__field__input {
        max-width: 80px;
        @include is-palmtop-wider {
          max-width: 129px;
        }
      }
    }
  }
  &__content {
    &__catalog {
      &__menu {
        display: block;
        margin-top: -25px;
        &__group {
          &:not(:first-child){
            margin-top: 12px;
          }
          .mwform-radio-field {
            width: auto;
            display: inline-block;
            margin-right: -30px;
          }
          &__note {
            display: inline-block;
            padding-top: 10px;
            margin-left: 45px;
            line-height: 1.92em;
          }
        }
      }
      &__list {
        margin-top: 37px;
        &__item {
          display: flex;
          flex-wrap: wrap;
          @include is-laptop-wider {
            position: relative;
            display: block;
          }
          &:not(:first-child){
            margin-top: 20px;
            @include is-laptop-wider {
              margin-top: 30px;
            }
          }
          &:nth-child(1){
            min-height: 184px;
          }
          &:nth-child(2){
            min-height: 185px;
          }
          &:nth-child(3){
            min-height: 157px;
          }
          &__checkbox {
            width: 100%;
            margin-top: 0;
            @include is-laptop-wider {
              padding-left: 130px;
              margin-left: 36px;
            }
            .mwform-checkbox-field {
              width: 100%;
              @include is-laptop-wider {
                padding-left: 0;

              }
            }
          }
          &__image {
            max-width: 130px;
            margin-top: 20px;
            @include is-laptop-wider {
              position: absolute;
              top: 0;
              left: 0;
              margin-top: 0;
            }
          }
          &__paragraph {
            width: calc(100% - 130px);
            padding-left: 20px;
            box-sizing: border-box;
            @include is-laptop-wider {
              width: 100%;
              padding-left: 130px;
              margin-left: 36px;
            }
          }
        }
      }
    }
  }
  &__privacy {
    margin-top: 40px;
    padding-bottom: 20px;
    background-image: url('../../assets/images/common/bg_dot_line.png');
    background-repeat: repeat-x;
    background-position: center bottom;
    &__anchor {
      text-align: right;
      &__link {
        font-size: 1.3rem;
        letter-spacing: .15em;
        color: $color-key;
        position: relative;
        padding-left: 15px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 5px;
          left: 0;
          background-image: url('../../assets/images/common/ico_arrow_key.svg');
          width: 5px;
          height: 10px;
          background-size: 5px 10px;
        }
      }
    }
  }
  .error {
    color: #ea5655 !important;
    margin-top: 10px !important;
    font-size: 1.6rem !important;
  }
  .mwform-tel-field {
    input[type="text"], .mwform-zip-field input[type="text"] {
      display: inline-block;
      width: auto;
    }
    &__separator {
      font-size: 1.3rem;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .mwform-zip-field {
    &__separator {
      font-size: 1.3rem;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
