@charset "UTF-8";

//
// サイト全体で共通のウェブフォント
//

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700,900&subset=japanese');

// 英字用
@import url('https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap');
