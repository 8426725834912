@charset "UTF-8";

//
// セクション
//

.section {
  padding-top: 40px;
  padding-bottom: 40px;
  @include is-laptop-wider {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  &:not(:first-child) {
  }
  &:not(:last-child) {
  }
  &:first-child {
    padding-top: 40px;
    padding-bottom: 40px;
    @include is-laptop-wider {
      padding-top: 60px;
      padding-bottom: 100px;
    }
  }
  &--colored {
    background-color: $color-sub;
    padding-top: 40px;
    padding-bottom: 40px;
    @include is-laptop-wider {
      padding-top: 60px;
      padding-bottom: 100px;
    }
  }
}
